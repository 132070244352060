<template>
    <div>
        <b-table
            id="actionTable"
            class="m-0 text-center"
            :sort-desc.sync="dir"
            :items="evacuations"
            :fields="fields"
            :sort-by.sync="sort"
            :per-page="perPage"
            :current-page.sync="currentPage"
            foot-clone
            hover
            responsive>
            <template v-slot:cell(eva_adr_nom)="data">
                {{ data.item.eva_adr_nom }}<br>

            </template>
            <template v-slot:cell(eva_place)="data">
                {{ data.item.eva_adr_ad1 }}<br>
                {{ data.item.eva_adr_cp }} - {{ data.item.eva_adr_ville }}
            </template>
            <template v-slot:cell(eva_contact_nom)="data">
                {{ data.item.eva_contact_prenom }} {{ data.item.eva_contact_nom }}
            </template>
            <template v-slot:cell(eva_date_exer)="data" :data-sorter="data.item.eva_date_exer">
                <template v-if="data.item.eva_date_exer">
                    {{
                        data.item.eva_date_exer | moment('Do MMMM YYYY')
                    }}
                </template>
            </template>
            <template v-slot:cell(url)="data">

                <b-button
                    v-if="data.item.url"
                    :href="data.item.url"
                    target="_blank"
                    class="btn btn-icon btn-circle btn-light btn-sm svg-icon svg-icon-primary"
                    v-b-tooltip.hover="'Consulter le rapport d\'évacuation'"
                >
                    <inline-svg src="/media/svg/icons/General/Visible.svg"></inline-svg>
                </b-button>

            </template>
        </b-table>
        <div class="d-flex justify-content-center align-items-center">
            <b-pagination
                v-model="currentPage"
                :total-rows="evacuations.length"
                :per-page="perPage"
                aria-controls="actionTable"
                size="lg"
                class="mt-5"
            ></b-pagination>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    props: ['sortBy', 'evacuations'],
    data() {
        return {
            dir: true,
            sort: this.sortBy,
            perPage: 10,
            currentPage: 1,
            fields: [
                {key: 'eva_adr_nom', label: this.$t('EVACUATION.NAME'), sortable: true},
                {key: 'eva_place', label: this.$t('EVACUATION.PLACE'), sortable: true},
                {key: 'eva_contact_nom', label: this.$t('EVACUATION.CONTACT_NAME'), sortable: true},
                {key: 'eva_date_exer', label: this.$t('EVACUATION.DATE_EXERCICE'), sortable: true},
                {
                    key: 'url',
                    label: this.$t('EVACUATION.RAPPORT'),
                    sortable: false
                }
            ],
            items: []
        }
    },
    watch: {
        sort: function (newValue) {
            this.$emit('change-sort', {
                sortBy: newValue,
                dir: this.dir
            })
        },
        dir: function (newValue) {
            this.$emit('change-sort', {
                sortBy: this.sort,
                dir: newValue
            })
        }
    },
    computed: {
        ...mapGetters({
            currentUser: 'currentUser'
        })
    }
}
</script>
